// polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Vue
import Vue from 'vue';
// import Poll from '../src/components/Poll';
import TurbolinksAdapter from 'vue-turbolinks';
import Toasted from 'vue-toasted';

// Rails
// for method: :delete links and data-confirm, and so on
import Rails from '@rails/ujs';
Rails.start();

// our css and font awesome icons
import '../css/application.scss';
import '@fortawesome/fontawesome-free/js/all';

import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

// required if not using app/assets/javascript/application.js
// if using app/assets/javascript/application.js it should be loaded before
if (typeof Turbolinks === 'undefined') {
  var Turbolinks = require('turbolinks');
  Turbolinks.start();
}

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  if (document.body.dataset.user) {
    Vue.use(Toasted, { theme: 'outline' });

    // Notifications polling is deactivated (deprecated feature)
    // const app = new Vue({
    //   render: h => h(Poll),
    // }).$mount();
    // document.body.appendChild(app.$el);
  }
  $('.select2').select2({ width: '100%' });

  function removeElement(event) {
    const target = event.target;
    target.style.display = 'none';
  }

  // Get elements by class name
  const removeableElements = document.querySelectorAll('.remove-by-click');

  // Attach a click event listener to each element
  for (let i = 0; i < removeableElements.length; i++) {
    removeableElements[i].addEventListener('click', removeElement);
  }
  const dismissibleElements = document.querySelectorAll('.dismiss-me');
  setTimeout(() => {
    dismissibleElements.forEach(el => (el.style.display = 'none'));
  }, 3200);
});
